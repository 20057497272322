import store from "@/store";
import { Auth } from "@/types/auth";
import { Http, JsonResponse } from "@/types/http";
import { Loading } from "@twjs/vue-loading";
import { Notice } from "@twjs/vue-notice";
import { distanceInWordsStrict, format } from "date-fns";
import { get } from "js-cookie";
import { finalize, map } from "rxjs/operators";
import { defineComponent, PropType } from "vue";
import { Filter, ParticipantFilter } from "./Filter";
import { SponsorshipLogModal } from "./SponsorshipLogModal";

const Participant = defineComponent({
  props: {
    name: String,
    userId: Number,
    age: String,
    gender: String,
    tripId: Number,
    startDateTime: String,
    endDateTime: String,
    startAddress: String,
    endAddress: String,
    activityDuration: Number,
    isCTA: Boolean,
    sponsorshipTripId: {
      type: Number,
      required: true,
    },
    onClick: { type: Function as PropType<(id: number) => void>, required: true },
  },
  render() {
    return (
      <tr onClick={() => this.onClick(this.sponsorshipTripId)}>
        <td
          class="text-center cursor-pointer"
          title="Click to get more detail"
          onClick={() => this.$router.push(`/participants/trip/${this.tripId}`)}
        >
          {this.tripId}
        </td>
        <td class="text-center">{this.startDateTime && format(this.startDateTime, "MM/DD/YYYY hh:mm A")}</td>
        <td class="text-center">{this.endDateTime && format(this.endDateTime, "MM/DD/YYYY hh:mm A")}</td>
        <td class="text-center leading-4 whitespace-pre-wrap">{this.startAddress}</td>
        <td class="text-center leading-4 w-24 whitespace-pre-wrap">{this.endAddress}</td>
        <td class="text-center">{this.activityDuration}</td>
        <td class="text-center">{this.isCTA ? "Yes" : "No"}</td>
      </tr>
    );
  },
});

export default defineComponent({
  props: {
    $http: { type: Object as PropType<Http>, required: true },
    $loading: { type: Object as PropType<Loading>, required: true },
    $notice: { type: Object as PropType<Notice>, required: true },
    $auth: { type: Object as PropType<Auth>, required: true },
  },
  data() {
    return {
      items: [],
      selectedId: -1,
      isOpenDetail: false,
    };
  },
  mounted() {
    const prevState = store.state.prevState.sponsorshipParticipant;
    this.init(prevState);
  },
  methods: {
    init(filter?: ParticipantFilter) {
      const url = this.buildUrl(filter);

      this.$loading.show();
      this.$http
        .get<JsonResponse<any>>(url)
        .pipe(
          map((json) => json.data),
          map((items: any[]) =>
            items.map((item: any) => ({
              name: `${item.first_name ?? ""} ${item.last_name ?? ""}`.trim(),
              userId: item.id,
              age: item.age,
              gender: item.sex === 1 ? "Male" : "Female",
              tripId: item.trip_id,
              startDateTime: item.start_time,
              endDateTime: item.end_time,
              startAddress: item.start_location,
              endAddress: item.end_location,
              activityDuration: this.caculationDuration(item.start_time, item.end_time),
              isCTA: item.is_cta === 1,
              sponsorshipTripId: item.sponsorship_trip_id,
            })),
          ),
          finalize(this.$loading.hide),
        )
        .subscribe(
          (data: any) => {
            this.items = data;
          },
          (err: Error) => {
            this.$notice.e("Failed to load data: " + this.$http.getErrorData(err).message);
          },
        );
    },
    caculationDuration(startDate: string, endDate: string) {
      if (!startDate || !endDate) {
        return "-";
      }

      return distanceInWordsStrict(startDate, endDate);
    },
    openDetail(id: number) {
      this.selectedId = id;
      this.isOpenDetail = true;
    },
    async exportCsv() {
      const token = get("auth.token");
      if (!token) {
        this.$notice.e("Please login to continue.");
        this.$auth.logout();
        return;
      }

      try {
        this.$loading.show();
        const response = await fetch(
          `${process.env.VUE_APP_HTTP_ENDPOINT}/admin/company/sponsorship/participants/${this.$route.params.id}/csv`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (!response.ok) {
          this.$notice.e("Failed to export csv!");
          return;
        }

        const body = await response.text();

        const a = document.createElement("a");
        a.href = "data:attachment/csv," + encodeURIComponent(body);
        a.target = "_blank";
        a.download = "transactions-data.csv";
        a.classList.add("hidden");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        this.$notice.s("Export csv successfully!");
      } catch (e) {
        this.$notice.e("Failed to get csv: " + (e as any).message);
      } finally {
        this.$loading.hide();
      }
    },
    buildUrl(filter?: ParticipantFilter) {
      if (!filter) {
        return `/admin/company/sponsorship/participants/${this.$route.params.id}?insight=1`;
      }

      const rawParams: Record<string, string> = {
        trip_id: filter.id,
        name: filter.name,
        start_date: filter.startDate,
        end_date: filter.endDate,
      };

      const params = Object.fromEntries(Object.entries(rawParams).filter(([, v]) => v !== null && v !== undefined && v !== ""));
      const query = new URLSearchParams(params);

      return `/admin/company/sponsorship/participants/${this.$route.params.id}?insight=1&${query.toString()}`;
    },
  },
  render() {
    return (
      <div>
        <w-ph class="my-10" text="Participants" />
        <Filter
          onFilter={(data: ParticipantFilter) => {
            this.init(data);
          }}
          onDownload={() => {
            this.exportCsv();
          }}
        />
        <div class="w-full mt-10 bg-white rounded-lg border border-solid border-gray-400">
          <div class="table-over">
            <table class="table participants">
              <thead>
                <tr>
                  <th class="text-center">Trip Id</th>
                  <th class="text-center">Start date & Time</th>
                  <th class="text-center">End Date & Time</th>
                  <th class="text-center">Start Location</th>
                  <th class="text-center">End Location</th>
                  <th class="text-center">Activity Dur</th>
                  <th class="text-center">CTA</th>
                </tr>
              </thead>
              <tbody>
                {this.items &&
                  this.items.length > 0 &&
                  this.items.map((trip: any) => <Participant key={trip.userId} {...trip} onClick={this.openDetail} />)}
              </tbody>
            </table>
            {this.items.length === 0 && <p class="p-2 text-center font-normal text-default-400">No Records</p>}
          </div>
        </div>
        <SponsorshipLogModal
          isOpen={this.isOpenDetail}
          selectedId={this.selectedId}
          onHide={() => {
            this.isOpenDetail = false;
            this.selectedId = -1;
          }}
        />
      </div>
    );
  },
});
